import * as React from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

const Languages = ({ className, menu }) => {
  const { language, languages, changeLanguage, t } = useI18next();
  const onClick = React.useCallback(
    (lng) => (e) => {
      e.preventDefault();
      changeLanguage(lng);
    },
    [changeLanguage]
  );
  const finalLanguagesList = menu ? [...languages] : languages.filter((lng) => lng !== language);

  return (
    <div className={`languagesContainer px-0 sm:ml-2 ${className}`}>
      <a href="#" title={t(`languages.${language}`)} className={`currentLng flag hide-for-mobile xl:text-lg ${language}`}>
          <span className="invisible">
              <Trans i18nKey={language} />
          </span>
      </a>
      <ul className="languages">
          {finalLanguagesList.map((lng) => (
            <li key={lng}>
                <a
                  href="#" 
                  className={`flag xl:text-lg ${lng}`} 
                  onClick={onClick(lng)}
                  title={t(`languages.${lng}`)}
                >
                  <span className="invisible">
                    <Trans i18nKey={lng} />
                  </span>
                </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default React.memo(Languages);
