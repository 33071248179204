import React from 'react';

const DabaStoreIcon = (props) => {
  return (
    <svg
      version='1.1'
      baseProfile='tiny'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      overflow='visible'
      viewBox="0 0 150 41.717" enable-background="new 0 0 150 41.717"
      {...props}
    >
      <g>
      <path fill="#2581FD" d="M19.261,14.642c-2.412,0-4.825,0.01-7.237-0.003c-2.021-0.011-2.897-0.782-3.317-2.732
		C7.486,6.243,7.954,8.19,2.958,7.549C0.749,7.266,0.232,6.463,0.062,4.577C-0.189,1.803,0.26,0.187,2.643,0.082
		c2.897,0.022,5.86-0.283,8.684,0.16c1.906,0.375,2.859,1.241,2.934,3.211c0.015,0.402,0.001,1.264,0.015,1.666
		c0.069,1.926,0.709,2.581,2.625,2.592c3.446,0.021,6.893-0.036,10.338,0.026c5.859,0.105,11.087,4.197,12.541,9.737
		c1.616,6.156-1.706,12.685-7.63,15.039c-1.835,0.729-3.713,1.079-5.695,1.045c-3.215-0.055-6.433-0.041-9.649-0.007
		c-1.871,0.02-3.164-0.575-3.697-2.564c-1.026-3.826-2.362-4.407,2.956-4.368c3.949,0.029,7.922,0.404,11.855-0.252
		c3.585-0.598,5.22-2.743,4.973-6.378c-0.214-3.136-2.477-5.206-5.879-5.329c-1.319-0.048-2.642-0.009-3.963-0.009
		c-1.264,0-2.527,0-3.791,0C19.261,14.648,19.261,14.645,19.261,14.642z"/>
      <path fill="#7EBCFC" d="M18.893,17.061c2.241,0,4.482-0.007,6.724,0.006c0.513,0.003,1.038,0.019,1.537,0.127
		c2.16,0.469,3.685,2.18,3.482,3.857c-0.203,1.687-1.842,2.874-4.106,2.887c-4.195,0.024-8.39-0.009-12.585,0.015
		c-1.581,0.009-2.625-0.656-3.123-2.169c-0.305-0.926-0.641-1.842-0.941-2.77c-0.418-1.293-0.042-1.982,1.426-1.961
		C13.836,17.089,16.364,17.063,18.893,17.061z"/>
      <circle fill="#007FFF" cx="18.496" cy="38.092" r="3.626"/>
      <circle fill="#007FFF" cx="30.968" cy="38.092" r="3.626"/>
      <g>
        <path fill="#2581FD" d="M52.791,13.333h-5.717v16.29h5.717c4.73,0,7.465-3.002,7.465-8.145
			C60.256,16.149,57.706,13.333,52.791,13.333z M52.277,26.132h-1.234v-9.308h1.234c2.632,0,3.887,1.489,3.887,4.654
			C56.164,24.48,54.786,26.132,52.277,26.132z"/>
        <path fill="#2581FD" d="M66.919,17.289c-3.146,0-5.121,1.606-5.244,4.212h3.434c0.062-0.838,0.638-1.326,1.501-1.326
			c0.987,0,1.44,0.605,1.44,1.931v0.209c-4.709,0-6.786,1.187-6.786,3.933c0,2.257,1.398,3.63,3.743,3.63
			c1.522,0,2.571-0.582,3.188-1.745l0.206,1.489h3.311v-7.679C71.711,18.895,70.065,17.289,66.919,17.289z M68.071,25.295
			c0,1.14-0.658,1.839-1.789,1.839c-0.864,0-1.378-0.465-1.378-1.164c0-0.931,0.987-1.396,3.167-1.396V25.295z"/>
        <path fill="#2581FD" d="M80.471,17.289c-1.46,0-2.694,0.768-3.29,2.025v-6.213H73.5v16.523h3.434l0.206-1.769
			c0.617,1.28,1.851,2.048,3.331,2.048c2.632,0,4.38-2.49,4.38-6.307C84.851,19.826,83.083,17.289,80.471,17.289z M79.114,26.761
			c-1.234,0-2.015-1.21-2.015-3.165c0-1.955,0.781-3.165,2.015-3.165c1.213,0,1.995,1.233,1.995,3.165
			C81.109,25.527,80.327,26.761,79.114,26.761z"/>
        <path fill="#2581FD" d="M91.473,17.289c-3.146,0-5.121,1.606-5.244,4.212h3.434c0.062-0.838,0.637-1.326,1.501-1.326
			c0.987,0,1.44,0.605,1.44,1.931v0.209c-4.709,0-6.786,1.187-6.786,3.933c0,2.257,1.398,3.63,3.743,3.63
			c1.522,0,2.57-0.582,3.187-1.745l0.206,1.489h3.311v-7.679C96.265,18.895,94.62,17.289,91.473,17.289z M92.625,25.295
			c0,1.14-0.658,1.839-1.789,1.839c-0.864,0-1.378-0.465-1.378-1.164c0-0.931,0.987-1.396,3.167-1.396V25.295z"/>
        <path fill="#2581FD" d="M105.334,19.803l-0.966-0.233c-1.789-0.442-2.447-0.884-2.447-1.745c0-0.884,0.72-1.443,1.789-1.443
			c1.193,0,1.974,0.652,2.118,1.722h3.66c-0.206-3.072-2.447-5.05-5.717-5.05c-3.393,0-5.655,2.118-5.655,5.213
			c0,2.49,1.481,4.049,4.606,4.771l0.905,0.209c1.769,0.419,2.406,0.884,2.406,1.722c0,0.931-0.802,1.559-1.995,1.559
			c-1.398,0-2.427-0.861-2.468-2.048h-3.825c0.164,3.444,2.57,5.422,6.19,5.422c3.743,0,5.984-2.094,5.984-5.422
			C109.919,21.897,108.665,20.617,105.334,19.803z"/>
        <path fill="#2581FD" d="M115.76,25.481v-4.98h1.871v-2.932h-1.995V14.59h-3.558v2.979h-1.275v2.932h1.275v5.283
			c0,2.816,1.172,4.096,3.702,4.096c0.658,0,1.398-0.116,1.933-0.256v-3.049c-0.206,0.047-0.514,0.093-0.823,0.093
			C116.13,26.668,115.76,26.319,115.76,25.481z"/>
        <path fill="#2581FD" d="M124.418,17.289c-3.352,0-5.655,2.583-5.655,6.306c0,3.723,2.303,6.307,5.655,6.307
			c3.352,0,5.614-2.583,5.614-6.307C130.032,19.873,127.769,17.289,124.418,17.289z M124.397,26.761
			c-1.193,0-1.954-1.233-1.954-3.165c0-1.955,0.761-3.165,1.954-3.165c1.213,0,1.954,1.21,1.954,3.165
			C126.351,25.527,125.61,26.761,124.397,26.761z"/>
        <path fill="#2581FD" d="M135.008,19.36l-0.206-1.792h-3.434v12.054h3.681v-5.352c0-2.025,0.967-3.188,2.632-3.188
			c0.35,0,0.617,0.046,0.823,0.116v-3.747c-0.247-0.07-0.555-0.116-0.864-0.116C136.509,17.336,135.543,18.057,135.008,19.36z"/>
        <path fill="#2581FD" d="M150,23.736c0-3.979-1.974-6.446-5.45-6.446c-3.434,0-5.552,2.513-5.552,6.306
			c0,3.77,2.2,6.307,5.614,6.307c2.694,0,4.915-1.652,5.326-4.119h-3.599c-0.267,0.768-0.864,1.164-1.645,1.164
			c-1.254,0-1.995-0.954-1.995-2.49h7.3V23.736z M142.741,22.13c0.123-1.21,0.802-1.955,1.851-1.955
			c1.008,0,1.645,0.721,1.789,1.955H142.741z"/>
      </g>
    </g>
    </svg>
  );
};

export default DabaStoreIcon;
