import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Footer from './Footer';
import Header from './Header';
import { ToastProvider } from 'react-toast-notifications';
import WhatsAppWidget from 'react-whatsapp-chat-widget';
import 'react-whatsapp-chat-widget/index.css';
import '../../css/whatsapp.css';
import Logo from '../../assets/images/logo.png';

const Layout = ({ children }) => {
  const { t } = useTranslation();

  return (
    <ToastProvider
      placement='bottom-right'
      autoDismiss={true}
      autoDismissTimeout='10000'
      transitionDuration='800'
    >
      <Header />
      <main id='main' className='text-gray-900'>
        {children}
      </main>
      <Footer />
      <WhatsAppWidget
        phoneNo='+2120661137928'
        position='right'
        widgetWidth='300px'
        widgetWidthMobile='260px'
        messageBox={true}
        iconSize='60'
        headerIcon={Logo}
        headerTitle='DabaStore'
        headerCaption='Online'
        chatPersonName={t('whatsapp.chatPersonName')}
        chatMessage={<div dangerouslySetInnerHTML={{ __html: t('whatsapp.chatMessage') }} />}
        btnTxt={t('whatsapp.btnTxt')}
        messageBoxTxt={t('whatsapp.messageBoxTxt')}
      />
    </ToastProvider>
  );
};

export default Layout;
