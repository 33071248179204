import * as React from "react";

// social media icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
//import TwitterIcon from "@mui/icons-material/Twitter";

const SocialMedia = () => {
    const socialMediaList = React.useMemo(() => [
        {
            link: "https://www.linkedin.com/company/dabastore/",
            render: (props) => <LinkedInIcon {...props} />
        },
        {
            link: "https://www.youtube.com/@DabaStore",
            render: (props) => <YouTubeIcon {...props} />
        },
        {
            link: "https://web.facebook.com/Dabastore.ma",
            render: (props) => <FacebookIcon {...props} />
        },
        {
            link: "https://www.instagram.com/dabastore_ma/",
            render: (props) => <InstagramIcon {...props} />
        },
        /*{
            link: "https://twitter.com/go_demand",
            render: (props) => <TwitterIcon {...props} />
        },*/
    ], []);
    return (
        <ul className="flex-1 flex flex-row mt-1">
            {
                socialMediaList.map(({ render, link }) => (
                    <li key={link} className="pr-2">
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            { 
                                render({ 
                                    htmlColor: "#616161",
                                    sx: { "&:hover": { color: "#287dfa" }},
                                    fontSize: "large"
                                })
                            }
                        </a>
                    </li>
                ))
            }
        </ul>
    );
};

export default SocialMedia;
